<template>
	<div class="home">
		<Banner :list="banners" :hadFrom="true"></Banner>
		<div class="home-news-box">
			<div class="home-news container">
				<div class="home-news-logo">
					<img src="@/assets/image/45.png" alt="news" />
				</div>
				<div class="swiper-container news-container">
					<div class="swiper-wrapper">
						<template v-for="(item, index) in infoList">
							<div class="swiper-slide home-news-item"  @click="toArticle(item)">
								<a target="_blank">{{ item.title }}</a>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
		<!-- 建筑企业股权转让啊啊啊啊 -->
		<certificate></certificate>  

		<div class="home-scenes" style="background: #F7F7F7;">
			<div class="container">
				<div class="main-title">
					<h1 class="name">建筑资质服务全场景覆盖</h1>
					<h3 class="same">致力于让客户在一个平台解决所有资质业务需求</h3>
				</div>
				<div class="home-scenes-main">
					<div class="scenes-menu">
						<div class="flex align-center">
							<h4>业务范围</h4>
							<div class="list">
								<template v-for="(item, index) in categoryList">
									<div v-if="index < 5" class="item" :class="{ active: sencesActive == index }" @click="navServe(index)" @mouseenter="slectScenes(index, item.id)"
									 :key="index">
										{{ item.name }}
									</div>
								</template>
							</div>
						</div>
						<div class="more" @click="$toConnectLink">了解更多 >></div>
					</div>
					<div class="scenes-content">
						<div class="list">
							<div class="business flex flex-wrap">
								<div class="business-box flex align-center flex-wrap flex-1">
									<div class="business-box-video bg-cent">
										<video type="video/mp4" autoplay="autoplay" preload="none" controls="controls" playsinline="true" webkit-playsinline="true" x5-playsinline="true" x5-video-player-type="h5" 
										:src="video_url"></video>
									</div>

									<div class="business-box-list" v-for="(item,index) in businessBgList[0].list" :key="index" @click="navServe(sencesActive)">
										<div class="business-box-list-img">
											<div class="bg bg-cent" :style="{backgroundImage: 'url(' + item.img + ')'}"> </div>
										</div>
										<div class="business-box-list-b flex align-center justify-between py-2 pl-2 pr-1">
											<div class="test-hide">
												<h4>{{item.title}}</h4>
												<span>{{item.test}}</span>
											</div>
											<img class="mr-1" src="../assets/image/home/right.png" />
										</div>
									</div>

								</div>
								<div class="business-right bg-white">
									<div class="business-right-hread flex justify-between align-center">
										<div v-for="(item,index) in tablist" :key="index">
											<div @click="pikerTab(index)" class="tab-list">
												{{item}}
												<span :class="tabIndex == index ? 'span-status' : '' "></span>
											</div>
										</div>
									</div>
									<div class="business-right-cont mt-4">
										<div class="business-h">
											<template v-if="tabIndex == 0">
												<div class="business-right-cont-list flex align-center mb-3" v-for="(item,index) in  criterionList" :key="index" @click="toArticle(item)">
													<span></span>
													<p class="test-hide w-100">{{item.title}}</p>
												</div>
											</template>
											<template v-if="tabIndex == 1">
												<div class="business-right-cont-list flex align-center mb-3" v-for="(item,index) in  caseList" :key="index" @click="toArticle(item)">
													<span></span>
													<p class="test-hide w-100">{{item.title}}</p>
												</div>
											</template>
											<template v-if="tabIndex == 2">
												<div class="business-right-cont-list flex align-center mb-3" v-for="(item,index) in  questionList" :key="index" @click="toArticle(item)">
													<span></span>
													<p class="test-hide w-100">{{item.title}}</p>
												</div>
											</template>
										</div>
										<div class="business-more flex align-center justify-between" @click="navMore">
											<span>了解更多相关信息...</span>
											<img src="@/assets/image/home/right.png" />
										</div>
									</div>
								</div>
							</div>

							<connect></connect>

						</div>
					</div>
				</div>
			</div>
		</div>

		
		<div>
			<div class="bg-cent" :style="{backgroundImage: 'url(' + require('@/assets/image/home/01008.png') + ')'}">
				<div class="home-data container">
					<template v-for="(item, index) in list">
						<div class="home-data-item" :key="index">
							<div class="img-box">
								<img :src="item.icon" class="animated bounceIn" alt="datas" />
							</div>
						</div>
					</template>
				</div>
			</div>
			<div class="home-product container">
				<div class="main-title">
					<h1 class="name">助力10万+建企实现产业规模升级</h1>
				</div>
				<div class="home-product-main">
					<productBanner></productBanner>
				</div>
			</div>

			<div class="home-case container">
				<template v-for="(item, index) in allCase">
					<div class="item" @click="toCase" :key="index">
						<img :src="item" alt="" />
					</div>
				</template> 
			</div>

			<!--旗下产品 -->
			<div class="my-product" style="background: #F7F7F7;">
				<div class="container">
					<div class="my-product-title">
						<span>Brands</span>
						<h1>旗下品牌</h1>
					</div>
					<div class="my-product-cont flex align-center">
						<div class="my-product-cont-box">
							<div @mouseenter="pckerBrands(index)" class="my-product-cont-box-list flex align-center justify-center" v-for="(item,index) in brands"
							 :key="index">
								<img :src="item" />
							</div>
						</div>

						<template v-for="(item,index) in brandsCont">
							<div :key="index" v-if="brandsIndex == index">
								<div class="my-product-cont-box-right flex align-center justify-center bg-cent" :style="{backgroundImage: 'url(' + item.bg + ')' }">
									<p>{{item.test}}</p>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
			
		</div>

		<news ifcolor="aa"></news>

	</div>
</template>

<script>
	import Swiper from "swiper";
	import Banner from "@/components/common/banner";
	import productBanner from "@/components/home/product";
	import {
		WOW
	} from "wowjs";
	import connect from '@/components/common/connect.vue'
	import news from '../components/common/home/news.vue'
	export default {
		name: "Home",
		components: {
			Banner,
			productBanner,
			connect,
			news
		},
		data() {
			return {
				criterionList: [],
				caseList: [],
				questionList: [],
				businessBgList: [{
					list: [{
							img: require('@/assets/image/home/01037.png'),
							title: '建筑工程总承包',
							test: '效率高，时效快'
						},
						{
							img: require('@/assets/image/home/01038.png'),
							title: '公路工程总承包',
							test: '省心省时省力'
						}, {
							img: require('@/assets/image/home/01039.png'),
							title: '市政工程总承包',
							test: '一站式无忧服务'
						}, {
							img: require('@/assets/image/home/01040.png'),
							title: '机电工程总承包',
							test: '业界口碑信誉好'
						}
					]
				}, {
					list: [{
							img: require('@/assets/image/home/zy/01.png'),
							title: '电子与智能化工程专包',
							test: '通过率高下证快'
						},
						{
							img: require('@/assets/image/home/zy/02.png'),
							title: '机电安装工程专包',
							test: '不成功不收费'
						}, {
							img: require('@/assets/image/home/zy/03.png'),
							title: '装修装饰工程专包',
							test: '收费透明资源安全'
						}, {
							img: require('@/assets/image/home/zy/04.png'), 
							title: '地基基础工程专包',
							test: '通过率高下证快'
						}
					]
				},{
					list: [{
							img: require('@/assets/image/home/sj/01.png'),
							title: '建筑施工总承包',
							test: '新型工业管理系统'
						},
						{
							img: require('@/assets/image/home/sj/02.png'),
							title: '机电施工总承包',
							test: '新型工业管理系统'
						}, {
							img: require('@/assets/image/home/sj/03.png'),
							title: '市政工程施工总承包',
							test: '新型工业管理系统'
						}, {
							img: require('@/assets/image/home/sj/04.png'),
							title: '通信工程施工总承包',
							test: '新型工业管理系统'
						}
					]
				},{
					list: [{
							img: require('@/assets/image/home/qta/01.png'),
							title: '劳务资质',
							test: '不成功不收费'
						},
						{
							img: require('@/assets/image/home/qta/02.png'),
							title: '劳务资质',
							test: '通过率高下证快'
						}
					]
				}],
				tabIndex: 0,
				tablist: ['资质标准', '成功案例', '常见问题'],
				brandsIndex: 0,
				brands: [require('@/assets/image/home/logowx.png'), require('@/assets/image/home/logorl.png'), require(
					'@/assets/image/home/logozzy.png')],
				brandsCont: [{
						bg: require('@/assets/image/home/wx.png'),
						test: '优普道建筑网校是优普道集团旗下，开发的移动互联网职业教育一站式服务平台。凭借着优普道集团深耕建筑咨询行业的优势，深刻了解到人才培训教育对建筑企业的重要性。在建筑职业教育领域 纵深发展，为建筑企业培养出符合发展战略的优质人才！'
					},
					{
						bg: require('@/assets/image/home/rl.png'),
						test: '睿凌环境自2008年进入环境治理领域以来，经过十余年的发展，已布局全国，成为领先的生态环境综合服务商，公司业务遍及广州，四川，重庆，黑龙江，江西，广西，云南，贵州，湖南，甘肃，陕西，新疆，上海、内蒙古等十多个省份。睿凌环境秉承“坚持环保理念，共创绿色美丽家园”的企业宗旨，“履行人类对环境的责任并创造价值，绿水青山就是金山银山”为使命，针对不同客户的技术、运行和环境要求，为客户提供管家式服务。'
					},
					{
						bg: require('@/assets/image/home/zzy.png'),
						test: '专注鱼是优普道集团旗下企业综合管理与服务线上平台。业务涵盖工商咨询、财税筹划、知识产权、品牌推广、科技项目转化、企业咨询与管理、投融资与合作等领域，并通过诊断式企业服务，定制化解决方案，满足企业不同发展阶段的需求，致力打造一个“互联网+”的企业综合管理与服务线上平台。'
					},
				],
				//行业Id
				industry: 0,
				intelligentList: [],
				cover_url: "",
				stepHover: false,
				swiperHover: false,
				businessSwiper: null,
				businessList: [],
				businessActive: 0,
				businessBannerActive: 0,
				stepActive: 0,
				sencesActive: 0,
				banners: {
					title: "",
					desc: "",
					list: [],
				},
				list: [{
						icon: require("@/assets/image/home/01009.png"),
						title: "专注建企服务",
					},
					{
						icon: require("@/assets/image/home/01010.png"),
						title: "服务全国城市",
					},
					{
						icon: require("@/assets/image/home/01011.png"),
						title: "企业共同选择",
					},
					{
						icon: require("@/assets/image/home/01012.png"),
						title: "全国用户使用",
					},
				],

				business: [{
						name: "施工总承包资质",
						router: "/contract",
						img: require("@/assets/image/business1.jpg"),
						child: [{
								name: "建筑工程施工总承包",
								img: require("@/assets/image/business1.jpg"),
							},
							{
								name: "公路工程施工总承包",
								img: require("@/assets/image/business1.jpg"),
							},
							{
								name: "市政公用工程施工总承包",
								img: require("@/assets/image/business1.jpg"),
							},
							{
								name: "电力工程施工总承包",
								img: require("@/assets/image/business1.jpg"),
							},
						],
					},
					{
						name: "施工专业承包资质",
						router: "/qualification",
						img: require("@/assets/image/business2.jpg"),
						child: [{
								name: "防水防腐保温工程",
								img: require("@/assets/image/business2.jpg"),
							},
							{
								name: "建筑装修装饰工程",
								img: require("@/assets/image/business2.jpg"),
							},
							{
								name: "地基基础工程",
								img: require("@/assets/image/business2.jpg"),
							},
							{
								name: "建筑机电安装工程",
								img: require("@/assets/image/business2.jpg"),
							},
						],
					},
					{
						name: "工程设计资质",
						router: "/design",
						img: require("@/assets/image/business3.jpg"),
						child: [{
								name: "建筑装饰工程设计专项",
								img: require("@/assets/image/business3.jpg"),
							},
							{
								name: "环境工程设计专项",
								img: require("@/assets/image/business3.jpg"),
							},
							{
								name: "市政行业工程专项",
								img: require("@/assets/image/business3.jpg"),
							},
							{
								name: "建筑工程设计",
								img: require("@/assets/image/business3.jpg"),
							},
						],
					},
					{
						name: "其他建筑资质",
						router: "/decoration",
						img: require("@/assets/image/business4.jpg"),
						child: [{
								name: "施工劳务企业资质",
								img: require("@/assets/image/business4.jpg"),
							},
							{
								name: "承装（修、试）电力设施许可证",
								img: require("@/assets/image/business4.jpg"),
							},
						],
					},
				],
				ensure: [{
						img: require("@/assets/image/46.png"),
						title: "低价透明",
						desc: "业内统一报价<br>无任何隐形消费",
					},
					{
						img: require("@/assets/image/47.png"),
						title: "一站式服务",
						desc: "一对一专属顾问<br>7*24小时一站式服务",
					},
					{
						img: require("@/assets/image/48.png"),
						title: "信息保密",
						desc: "客户信息保密<br>安全保障 绝不泄露",
					},
					{
						img: require("@/assets/image/49.png"),
						title: "售后无忧",
						desc: "服务过程全面跟进<br>资深顾问专业解答",
					},
				],
				step: [{
						step: "提出资质需求",
						name: "提出资质需求",
						sname: "FIRST STEP",
						desc: "根据企业的实际发展需求和需要承接的项目给出最全面最优的建议方案，再根据实际面谈协商，签订资质合同。",
						img: require("@/assets/image/step1.png"),
					},
					{
						step: "配备资质人员",
						name: "配备资质人员",
						sname: "SECOND STEP",
						desc: "根据企业办理所需的资质、细分所属领域进行资质人员的补充和沟通，匹配资质所需要的人才及为企业发展提供人才供给。",
						img: require("@/assets/image/step2.png"),
					},
					{
						step: "申报资料",
						name: "申报资料",
						sname: "THIRD STEP",
						desc: "根据已有的申报资料，对其整理后和相关部门负责人进行详细沟通，通过网络申报电子版资料，为企业作出最完善的资料提交。",
						img: require("@/assets/image/step3.png"),
					},
					{
						step: "取得企业资质",
						name: "取得企业资质",
						sname: "FOURTH STEP",
						desc: "经过上述三个步骤后，最终等待住建委初审意见公示，然后到最终住建委核准意见公示，最快的速度获取资质证书，承接项目。",
						img: require("@/assets/image/step4.png"),
					},
				],
				allCase: [
					require("@/assets/image/case/1.png"),
					require("@/assets/image/case/2.png"),
					require("@/assets/image/case/4.png"),
					require("@/assets/image/case/5.png"),
					require("@/assets/image/case/6.png"),
					require("@/assets/image/case/7.png"),
					require("@/assets/image/case/8.png"),
					require("@/assets/image/case/9.png"),
					require("@/assets/image/case/10.png"),
					require("@/assets/image/case/11.png"),
					require("@/assets/image/case/14.png"),
					require("@/assets/image/case/15.png"),
					require("@/assets/image/case/16.png"),
					require("@/assets/image/case/20.png"),
					require("@/assets/image/case/21.png"),
				],

				infoList: [],
				categoryList: [{
						name: '总承包资质',
						id: 10
					},
					{
						name: '专业承包资质',
						id: 20
					},
					{
						name: '设计类资质',
						id: 30
					},
					{
						name: '其他类资质',
						id: 40
					},
				],
				scope: 10,
				video_url: ''
			};
		},

		methods: {
			
			// 点击跳转服务
			navServe(index){
				let url = ['/contract','/qualification','/design','/decoration'];
				this.$router.push(url[index]);
			},
			// 点击更多了解详情
			navMore(){
				let url = ['/standard','/case','/policy?type=2']; 
				this.$router.push(url[this.tabIndex]);
			},
			pikerTab(index) {
				// console.log(index)
				this.tabIndex = index;
			},
			pckerBrands(index) {
				console.log(index);
				if (this.brandsIndex == index) return;
				this.brandsIndex = index;
			},
			toPage(val) {
				this.$router.push(val);
			},
			getBannerList() {
				this.$api
					.getBannerList({
						// 10首页  20
						launchPage: 10,
						// 10 pc
						launchFacility: this.$mobile ? 20 : 10,
					})
					.then((res) => {
						this.banners.list = res.data.list;
					});
			},
			getIntelligentList() {
				this.$api
					.getIntelligentList({
						industry: this.industry,
						limit: 4,
					})
					.then((res) => {
						this.intelligentList = res.data.list;
					});
			},

			toArticle(item) {
				this.$router.push({
					name: "article",
					query: {
						id: item.id,
						flag: item.flag || 0,
						except: item.except || 0,
						categoryId: item.category_id || 0,
						professionId: item.profession || 0,
					},
				});
			},
			changeSwiper(index) {
				this.businessBannerActive = index;
				this.businessSwiper.stopAutoplay();
				this.businessSwiper.slideTo(index + 1, 1000, false);
			},
			startAutoPlay() {
				this.businessSwiper.startAutoplay();
			},
			hoverBusiness(index) {
				this.swiperHover = true;
				if (this.businessActive == index) return;
				this.businessActive = index;
				this.businessList = this.business[this.businessActive].child;
				this.businessSwiper.destroy(false);
				this.initBusinessSwiper();
			},
			toCase() {
				this.$router.push("case");
			},

			slectStep(index) {
				if (this.stepActive == index) return;
				this.stepHover = true;
				this.stepActive = index;
			},
			slectScenes(index, id) {
				this.sencesActive = index;
				this.industry = id;
				this.scope = id;
				this.getQuestions();
				// this.getIntelligentList();
				// 接分类接口

			},
			initNewsSwiper() {
				var newsSwiper = new Swiper(".news-container", {
					loop: true,
					speed: 1000,
					autoplay: 3000,
					// direction: 'vertical',
					slidesPerView: 3,
					slidesPerGroup: 3,
				});
			},
			initBusinessSwiper() {
				let _this = this;
				this.businessSwiper = new Swiper(".business-container", {
					loop: true,
					autoplay: 2000,
					speed: 1000,
					effect: "fade",
					fade: {
						crossFade: true,
					},
					observer: true,
					observerParents: true,
					onSlideChangeStart: function(swiper) {
						_this.businessBannerActive = swiper.realIndex;
					},
				});
			},

			getInfo(flag) {
				this.$api
					.getArticleList({
						flag: flag,
						limit: 5,
					})
					.then((res) => {
						if (flag == 'info') {
							this.infoList = res.data.list;
							this.$nextTick(() => {
								this.initNewsSwiper();
							});
						} else if (flag == 'criterion') {
							this.criterionList = res.data.list;
						} else if (flag == 'case') {
							this.caseList = res.data.list;
						} else if (flag == 'question') {
							this.questionList = res.data.list;
						}

					});
			},

			getCategoryList() {
				this.$api.getArticleProfession().then((res) => {
					this.categoryList = res.data;
					this.industry = this.categoryList[0].id;
					this.getIntelligentList();
				});
			},
			getQuestions() {
				let data = {
					subdoadmin: '',
					launchPage: 10,
					classify: 20,
					scope: this.scope
				}
				this.$api.getQuestions(data).then((res) => {
					this.video_url = res.data.list[0].video_url;
				})
			}
		},
		created() {
			this.businessList = this.business[this.businessActive].child;
			this.getInfo('info');
			this.getInfo('criterion');
			this.getInfo('case');
			this.getInfo('question');

			this.getQuestions();
			// 建筑资质服务全场景覆盖
			// this.getCategoryList();
			this.getBannerList();
		},

		mounted() {
			var wow = new WOW({
				boxClass: "wow",
				animateClass: "animated",
				offset: 0,
				mobile: true,
				live: true,
			});
			wow.init();
			this.initBusinessSwiper();
		},
	};
</script>
<style lang="scss">
	@import "@/assets/css/home.scss";

	.home-policy-main {
		.ant-tabs-nav .ant-tabs-tab-active {
			color: #FFB820;
		}

		.ant-tabs-ink-bar {
			background-color: #FFB820;
		}

		.ant-tabs-nav .ant-tabs-tab:hover {
			color: #FFB820;
		}
	}
</style>
